import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    primary: {
      main: '#1a9479',
    },
    brand: {
      500: '#1a9479',
    },
  },
  components: {
    Radio: {
      baseStyle: {
        control: {
          borderColor: '#1a9479',
        },
      },
    },
  },
})

export default theme
