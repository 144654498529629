import styled from 'styled-components'

const SurveyBox = styled.div`
  width: 100%;
  background-color: #1a9479;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FaqBox = styled.div`
  width: 100%;
  background-color: #e4f8f4;
  padding: 20px;
  border-radius: 10px;
  color: #1a9479;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  font-size: 21px;
  color: #2c2d32;
  font-weight: bold;
  line-height: 28px;
  margin-top: 0;
`

const Subtitle = styled.p`
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 13px;
  color: #939393;
`

const Icon = styled.img`
  margin-bottom: 15px;
  width: 60px;
  height: auto;
`

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export { SurveyBox, Title, Subtitle, BoxGrid, FaqBox, Icon }
