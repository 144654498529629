import styled from 'styled-components'

const Header = styled.div`
  height: 130px;
  background-color: #f8f8f8;
  position: relative;
  display: flex;
  justify-content: center;
  color: #fff;
  padding-top: 15px;
  font-size: 21px;
`

const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`

const LogoContainer = styled.div`
  grid-column: 2;
  justify-self: center;
`

const Logo = styled.img`
  width: 110px;
  height: auto;
`

const Back = styled.div`
  grid-column: 1;
  justify-self: start;
  cursor: pointer;
  margin-top: 20px;
`

const BottomRadius = styled.div`
  height: 40px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  border: 1px solid #ebebeb;
  border-bottom: none;
`

const SpaceContainer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
`

export {
  Header,
  BottomRadius,
  SpaceContainer,
  HeaderContent,
  Logo,
  LogoContainer,
  Back,
}
