import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  ExplanationHeader,
  ExplanationImage,
  ExplanationsText,
  InfoButton,
  NextButton,
  QuestionContainer,
  QuestionImg,
  QuestionSubtitle,
  QuestionText,
  RadioContainer,
  SubQuestionText,
} from './styles.js'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import { setAnswer } from '../../redux/slices/surveySlice.js'
import Content from '../../components/ui/content/content.jsx'

const Question = ({ question }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const value = useSelector((state) => {
    return state.survey.answers[question.id] || ''
  })

  const handleChange = (newValue) => {
    if (newValue !== null) {
      dispatch(setAnswer({ questionId: question.id, answer: newValue }))
    }
  }

  const goNext = () => {
    if (question?.id < 16) {
      navigate(`/question/${question.id + 1}`)
    } else {
      navigate('/resultaat')
    }
  }

  const isDisabled = () => {
    return value === ''
  }

  return (
    <>
      <Content>
        <QuestionContainer>
          <div>
            <QuestionText>{question?.text}</QuestionText>
            {question?.image && <QuestionImg src={question.image} />}
          </div>
          {question?.explanations && (
            <InfoButton
              size="sm"
              onClick={onOpen}
              colorScheme="teal"
              variant="outline"
            >
              Uitleg over de vraag
            </InfoButton>
          )}
          <div>
            {question?.subQuestions && question?.subQuestions.length > 0 ? (
              <div>
                {question.subQuestions.map((subQuestion) => (
                  <div key={subQuestion.id}>
                    <SubQuestionText>- {subQuestion.text}</SubQuestionText>
                  </div>
                ))}
                <RadioGroup
                  onChange={(newValue) => handleChange(newValue)}
                  value={value}
                  mt="40px"
                >
                  <Stack>
                    {question?.answers &&
                      question?.answers.map((answer, index) => (
                        <RadioContainer key={index}>
                          <Radio
                            colorScheme="brand"
                            value={answer === 'Ja' ? '1' : '0'}
                          >
                            {answer}
                          </Radio>
                        </RadioContainer>
                      ))}
                  </Stack>
                </RadioGroup>
              </div>
            ) : (
              <RadioGroup
                onChange={(newValue) => handleChange(newValue)}
                value={value}
              >
                <Stack>
                  {question?.answers &&
                    question?.answers.map((answer, index) => (
                      <RadioContainer key={index}>
                        <Radio
                          colorScheme="brand"
                          value={answer === 'Ja' ? '1' : '0'}
                        >
                          {answer}
                        </Radio>
                      </RadioContainer>
                    ))}
                </Stack>
              </RadioGroup>
            )}
            {question?.subtitle && (
              <QuestionSubtitle>{question.subtitle}</QuestionSubtitle>
            )}
            <NextButton
              isDisabled={isDisabled()}
              colorScheme="brand"
              onClick={goNext}
            >
              Verder
            </NextButton>
          </div>
        </QuestionContainer>
      </Content>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ExplanationHeader>
            {question?.explanations?.header}
          </ExplanationHeader>
          <ModalCloseButton />
          <ModalBody>
            {question?.explanations?.text.map((paragraph, index) => (
              <ExplanationsText key={index}>{paragraph}</ExplanationsText>
            ))}
            {question?.explanations?.images.map((imgPath, index) => (
              <ExplanationImage key={index} src={imgPath} />
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Question
