import { BoxGrid, FaqBox, Icon, Subtitle, SurveyBox, Title } from './styles.js'
import { useNavigate } from 'react-router-dom'
import faqIcon from '../../assets/faq-icon.svg'
import surveyIcon from '../../assets/survey-icon.svg'
import Content from '../../components/ui/content/content.jsx'
import { useEffect, useState } from 'react'
import { Spinner } from '@chakra-ui/react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLocations } from '../../redux/slices/surveySlice.js'

const Home = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const locations = useSelector((state) => state.survey.locations)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get('/api/locations')
        dispatch(setLocations(response.data.data))
        setIsLoading(false)
      } catch (err) {
        console.log(err.message)
        setIsLoading(false)
      }
    }

    if (navigator.onLine && locations.length === 0) {
      fetchData()
    }
  }, [])

  return isLoading ? (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="teal"
      size="xl"
    />
  ) : (
    <Content>
      <Title>
        Fijn dat je ons hebt <br /> gevonden!
      </Title>
      <Subtitle>
        Door regelmatig je voeten te checken voorkom <br /> je veel
        voetproblemen!{' '}
      </Subtitle>
      <BoxGrid>
        <SurveyBox onClick={() => navigate('/personal')}>
          <Icon src={surveyIcon} alt="icon" />
          <div>Start je voetencheck</div>
        </SurveyBox>
        <FaqBox onClick={() => navigate('/adviezen')}>
          <Icon src={faqIcon} alt="icon" />
          <div>Adviezen</div>
        </FaqBox>
      </BoxGrid>
    </Content>
  )
}

export default Home
