import { getDistance } from 'geolib'

const checkDistance = (point1, point2) => {
  if (point1 !== undefined && point2?.lat !== null) {
    const distance = getDistance(
      { latitude: point1.lat, longitude: point1.lng },
      { latitude: point2.lat, longitude: point2.lng }
    )

    return distance <= import.meta.env.VITE_DISTANCE
  }

  return false
}

export default checkDistance
