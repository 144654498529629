import { motion } from 'framer-motion'
import { LayoutContainer } from './styles.js'
const Content = ({ children }) => (
  <LayoutContainer>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  </LayoutContainer>
)

export default Content
