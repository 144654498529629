const checkAnswers = (obj) => {
  for (let key of ['16', '17']) {
    if (obj[key] === '1') {
      return 3
    }
  }

  for (let key in obj) {
    if (key !== '16' && key !== '17' && obj[key] === '1') {
      return 2
    }
  }

  return 1
}

export default checkAnswers
