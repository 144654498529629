import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  ExplanationHeader,
  ExplanationsText,
  ExplanationsList,
} from './styles.js'
import { Field, Form, Formik } from 'formik'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import {
  setPersonalData,
  updatePersonalData,
} from '../../redux/slices/surveySlice.js'
import Content from '../../components/ui/content/content.jsx'
import { postcodeValidator } from 'postcode-validator'
import Gdpr from '../../data/gdpr.js'
import axios from 'axios'
import { useState } from 'react'

const PersonalData = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [tagInputVal, setTagInputVal] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm()
  const toast = useToast()

  const onChangeTagInput = (e) => {
    setTagInputVal(e.target.value.replace(/[^a-zA-Z\d]/gi, '').toUpperCase())
  }

  const onPostcodeSubmit = ({ postcode }) => {
    if (navigator.onLine) {
      convertPostCode(postcode)
    } else {
      onOpen()
    }
  }

  const convertPostCode = async (postcode) => {
    setIsLoading(true)
    try {
      const response = await axios.get(`/api/locations/${postcode}`)
      const coords = response.data

      dispatch(updatePersonalData({ position: coords }))
      setIsLoading(false)
      onOpen()
    } catch (e) {
      setIsLoading(false)
      toast({
        title: 'Postcode onjuist!',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const onSubmit = () => {
    onClose()
    navigate('/question/1')
  }

  const validatePostcode = () => {
    let error

    if (!tagInputVal) {
      error = 'Dit veld is verplicht'
    } else if (!postcodeValidator(tagInputVal, 'NL')) {
      error = 'Postcode onjuist'
    }

    return error
  }

  return (
    <Content>
      <Formik
        initialValues={{ postcode: '' }}
        onSubmit={handleSubmit(onPostcodeSubmit)}
      >
        {(props) => (
          <Form>
            <Field name="postcode" validate={validatePostcode}>
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.postcode && form.touched.postcode}
                  {...register('postcode')}
                  mt="20vh"
                >
                  <FormLabel>Postcode</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<HiOutlineLocationMarker color="#1A9479" />}
                    />
                    <Input
                      {...field}
                      placeholder="Vul hier uw postcode in"
                      value={tagInputVal}
                      onChange={(e) => onChangeTagInput(e)}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.postcode || ''}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Center w="100%" mt="5">
              <Button
                colorScheme="brand"
                type="submit"
                isLoading={props.isSubmitting || isLoading}
              >
                Start
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {Gdpr?.map((rule, index) => (
            <div key={index}>
              <ExplanationHeader>{rule.title}</ExplanationHeader>
              {(rule.paragraphs || rule.list || rule.paragraphs_after) && (
                <ModalBody>
                  {rule.paragraphs?.map((paragraph, paragraphIndex) => (
                    <ExplanationsText
                      key={paragraphIndex}
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    ></ExplanationsText>
                  ))}
                  {rule.list && (
                    <ExplanationsList>
                      {rule.list?.map((listItem, listItemIndex) => (
                        <li key={listItemIndex}>{listItem}</li>
                      ))}
                    </ExplanationsList>
                  )}
                  {rule.paragraphs_after?.map((paragraph, paragraphIndex) => (
                    <ExplanationsText
                      key={paragraphIndex}
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    ></ExplanationsText>
                  ))}
                </ModalBody>
              )}
            </div>
          ))}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="checkbox1">
              <Checkbox
                {...register('checkbox1')}
                colorScheme="brand"
                isChecked={true}
                hidden
              />
            </FormControl>
            <FormControl id="checkbox2">
              <Checkbox
                {...register('checkbox2')}
                colorScheme="brand"
                isChecked={true}
                hidden
              />
            </FormControl>
            <Center w="100%" mb="20px">
              <Button colorScheme="brand" type="submit">
                Akkoord
              </Button>
            </Center>
          </form>
        </ModalContent>
      </Modal>
    </Content>
  )
}

export default PersonalData
