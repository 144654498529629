import styled from 'styled-components'
import Autocomplete from 'react-google-autocomplete'
import { ModalHeader } from '@chakra-ui/react'

const LayoutContainer = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`

const CheckboxLabel = styled.div`
  font-size: 13px;
`

const CheckboxContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const GoogleAutocomplete = styled(Autocomplete)`
  border: 1px solid #e2e8f0;
  width: 100%;
  height: 40px;
  border-radius: 0.375rem;
  padding-right: 16px;
  padding-left: 40px;

  &:focus {
    outline: none;
  }
`

const ExplanationsText = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  & > a {
    color: #1a9479;
  }
`

const ExplanationsList = styled.ul`
  margin-bottom: 20px;
  font-size: 16px;
  padding-left: 20px;
  margin-top: -5px;
`

const ExplanationHeader = styled(ModalHeader)`
  font-weight: bold !important;
  padding-right: 50px !important;
`

export { LayoutContainer, CheckboxLabel, CheckboxContainer, GoogleAutocomplete, ExplanationHeader, ExplanationsText, ExplanationsList }