import styled from 'styled-components'
import { Button } from '@chakra-ui/react'
const Result = styled.div`
  font-weight: 400;
`

const Link = styled.a`
  color: #06997c;
`

const NavigateButton = styled(Button)`
  margin-top: 20px;
`

const ActionButton = styled(Button)`
  margin-top: 20px;
`

const Logo = styled.img`
  width: 110px;
  height: auto;
  margin-bottom: 20px;
`

const CenterContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export {
  Result,
  ActionButton,
  CenterContainer,
  FlexColumn,
  Logo,
  Link,
  NavigateButton,
}
