import AppRouter from './router/router.jsx'
import { Provider } from 'react-redux'
import { store } from './redux/store.js'
import { registerSW } from 'virtual:pwa-register'

import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme/theme.js'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './redux/store.js'
import axios from 'axios'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.defaults.params = {}
axios.defaults.params['api_key'] = import.meta.env.VITE_API_KEY

registerSW({ immediate: true })

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <AppRouter />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
