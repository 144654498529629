import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Divider,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Link } from './styles.js'

const itemsPerPage = parseInt(import.meta.env.VITE_POINTS_PER_PAGE, 10)

const PointsList = ({ nearbyPoints, pdf = false }) => {
  const [displayIndex, setDisplayIndex] = useState(
    pdf ? nearbyPoints.length : itemsPerPage
  )

  const handleLoadMore = () => {
    setDisplayIndex((prevIndex) => prevIndex + itemsPerPage)
  }

  useEffect(() => {
    if (pdf) {
      setDisplayIndex(nearbyPoints.length)
    }
  }, [pdf, nearbyPoints])

  return (
    nearbyPoints.length > 0 && (
      <>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 21,
            marginBottom: 20,
          }}
        >
          Locatie in de buurt
        </p>
        <List spacing={3}>
          {nearbyPoints.slice(0, displayIndex).map((point, index) => (
            <ListItem key={index}>
              {!pdf && (
                <ListIcon as={HiOutlineLocationMarker} color="green.500" />
              )}
              {point?.company_name} <br />
              {point?.formatted_address.replace(', Nederland', '')} <br />
              {point?.phone && (
                <>
                  <Link href={`tel:${point.phone}`}>{point.phone}</Link>
                  <br />
                </>
              )}
              {point?.email && (
                <>
                  <Link href={`mail:${point.email}`}>{point.email}</Link>
                  <br />
                </>
              )}
            </ListItem>
          ))}
        </List>
        {!pdf && displayIndex < nearbyPoints.length && (
          <Button colorScheme="brand" onClick={handleLoadMore} mt={4}>
            Meer lezen
          </Button>
        )}
        <Box padding="5">
          <Divider />
        </Box>
      </>
    )
  )
}

export default PointsList
