const gdpr = [
    {
        title: 'PRIVACYVERKLARING VOETENCHECK.NL',
        paragraphs: [
            'Datum van laatste revisie: 25-10-2023',
            'Welkom bij Voetencheck.nl, een dienst aangeboden door ProVoet, de Brancheorganisatie voor de Pedicure. We hechten veel waarde aan uw privacy en persoonlijke gegevens. Deze privacyverklaring legt uit wat we met uw informatie doen, wanneer u onze website gebruikt en hoe we hiermee omgaan.',
        ]
    },
    {
        title: '1. Identiteit en Contact',
        paragraphs: [
            'Dit is de privacyverklaring van Voetencheck.nl, een initiatief van ProVoet. Heeft u vragen over deze verklaring of wilt u uw rechten uitoefenen, dan kunt u contact met ons opnemen via <a href="mailto:communicatie@provoet.nl">communicatie@provoet.nl</a> of door te bellen naar 0318515527.',
        ],
    },
    {
        title: '2. Welke Gegevens We Verzamelen',
        paragraphs: [
            'Alle gegevens worden alleen lokaal op uw apparaat opgeslagen en worden niet naar onze servers verzonden of op enige andere manier gedeeld met ProVoet. De informatie wordt verwijderd zodra u de website www.voetencheck.nl sluit. Uw locatiegegevens worden alleen gebruikt voor het tonen van pedicures in uw omgeving en worden niet opgeslagen of gedeeld met derden. Basale persoonsgegevens: uw voornaam, leeftijd, geslacht en woonplaats.',
            'Gezondheidsinformatie: uw antwoorden op vragen over de conditie van uw voeten en eventuele ziektebeelden worden ook niet door ons opgeslagen.'
        ],
    },
    {
        title: '3. Hoe en Waarom We Uw Gegevens Verwerken',
        paragraphs: [
            'Het verstrekken van gepersonaliseerd advies op basis van uw antwoorden. Het aanbevelen van pedicures in uw omgeving op basis van de locatiegegevens die u deelt. Het verbeteren van onze diensten door algemene statistieken over het gebruik van de test te verzamelen (deze statistieken bevatten geen persoonlijk identificeerbare informatie).',
        ],
    },
    {
        title: '4. Cookies',
        paragraphs: [
            'Onze website gebruikt geen traditionele cookies voor het bijhouden van gebruikersinformatie, aangezien alle gegevens lokaal worden opgeslagen en verwijderd na uw sessie. Echter, de website kan essentiële cookies gebruiken om de functionaliteit te waarborgen. Deze cookies verzamelen geen persoonlijke informatie.',
        ]
    },
    {
        title: '5. Uw Rechten',
        paragraphs: [
            'Onder de AVG heeft u diverse rechten. Omdat uw persoonlijke gegevens echter alleen lokaal worden opgeslagen en niet met ons worden gedeeld, zijn sommige van deze rechten in deze context niet van toepassing. Om uw rechten uit te oefenen, kunt u contact met ons opnemen via de contactgegevens vermeld in sectie 1.',
        ],
    },
    {
        title: '6. Wijzigingen in de Verklaring',
        paragraphs: [
            'We behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. Elke wijziging zal op deze pagina worden gepubliceerd. We raden u aan deze privacyverklaring regelmatig te raadplegen, zodat u altijd op de hoogte bent van de inhoud van de actuele privacyverklaring.'
        ]
    },
    {
        title: '7. Gebruik van Google Maps',
        paragraphs: [
            `Deze website maakt gebruik van Google Maps API's om pedicures in uw omgeving te vinden. Google Maps is een dienst van Google Inc. ("Google"). Door gebruik te maken van deze functie, gaat u akkoord met het verzamelen, verwerken en gebruiken van de automatisch verzamelde gegevens door Google, een van zijn vertegenwoordigers, of derde partijen.`,
            `De gebruiksvoorwaarden van Google Maps zijn te vinden onder de volgende link: <a href="https://www.google.com/intl/nl_nl/help/terms_maps/" target="_blank">Gebruiksvoorwaarden van Google Maps</a>.`,
            `Uitgebreide informatie over gegevensbescherming in verband met het gebruik van Google Maps is te vinden op de website van Google ("Google Privacy Policy"): <a href="https://policies.google.com/privacy" target="_blank">Google Privacy Policy</a>`
        ]
    },
]

export default gdpr
