import styled from 'styled-components'
import { Button, ModalHeader } from '@chakra-ui/react'

const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`

const QuestionText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #2c2d32;
`

const SubQuestionText = styled.div`
  font-size: 16px;
  color: #2c2d32;
  margin-bottom: 10px;
  margin-top: 10px;
`

const NextButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 40px;
`

const InfoButton = styled(Button)``

const RadioContainer = styled.div`
  background-color: #e6f8f4;
  padding: 10px;
  border-radius: 10px;
`

const QuestionImg = styled.img`
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: auto;
`

const ExplanationsText = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
`

const ExplanationHeader = styled(ModalHeader)`
  font-weight: bold !important;
  padding-right: 50px !important;
`

const ExplanationImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 10px;
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
`

const QuestionSubtitle = styled.p`
  margin-top: 20px;
  font-size: 13px;
`

export {
  QuestionContainer,
  QuestionText,
  RadioContainer,
  NextButton,
  SubQuestionText,
  InfoButton,
  QuestionImg,
  ExplanationsText,
  ExplanationHeader,
  ExplanationImage,
  QuestionSubtitle,
}
