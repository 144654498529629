import { useSelector } from 'react-redux'
import checkAnswers from '../../utils/checkAnswers.js'
import resultsMap from '../../data/results.js'
import { forwardRef, useCallback, useRef, useState } from 'react'
import {
  ActionButton,
  CenterContainer,
  Link,
  Logo,
  NavigateButton,
  Result,
} from './styles.js'
import { Box, Divider } from '@chakra-ui/react'
import { Online } from 'react-detect-offline'
import { Margin, usePDF } from 'react-to-pdf'
import Content from '../../components/ui/content/content.jsx'

import logo from '../../assets/logo.png'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import checkDistance from '../../utils/checkDistance.js'
import PointsList from './components/PointsList/index.js'
import { getDistance } from 'geolib'

const Summary = () => {
  const [isRenderingPDF, setIsRenderingPDF] = useState(false)
  const [map, setMap] = useState(null)
  const [selectedPoint, setSelectedPoint] = useState(null)
  const answers = useSelector((state) => state.survey.answers)
  const userCoords = useSelector((state) => state.survey.user?.position)
  const locations = useSelector((state) => state.survey.locations)
  const result = checkAnswers(answers)
  const mapRef = useRef(null)

  const { toPDF, targetRef } = usePDF({
    filename: 'voetencheck.pdf',
    page: { margin: Margin.SMALL },
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  })

  const convertCoordinates = ({ lat, lng }) => ({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  })

  const userLocation = convertCoordinates(userCoords)
  const showGoogleMaps = userLocation !== undefined && isLoaded

  const onLoad = useCallback(function callback(mapInstance) {
    mapInstance.setZoom(10)
    setMap(mapInstance)
    mapRef.current = mapInstance
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  const generatePDF = () => {
    setIsRenderingPDF(true)
    setTimeout(() => {
      toPDF().then(() => setIsRenderingPDF(false))
    }, 500)
  }

  const nearbyPoints = locations.filter((point) => {
    if (result === 3 && !point.med_pedicure) {
      return false
    }

    return checkDistance(userLocation, point)
  })

  const sortedLocations = nearbyPoints
    .map((point) => ({
      ...point,
      distance: getDistance(userLocation, point),
    }))
    .sort((a, b) => a.distance - b.distance)

  const PdfContent = forwardRef((props, ref) => (
    <div
      style={{
        width: '210mm',
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        display: isRenderingPDF ? 'block' : 'none',
      }}
    >
      <div ref={ref} style={{ textAlign: 'center', padding: 20 }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td align="center">
                <Logo src={logo} />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Uw resultaat:
        </div>
        <Result>
          {resultsMap[result]}{' '}
          <Link href="https://www.provoet.nl" target="_blank" rel="noreferrer">
            www.provoet.nl
          </Link>
        </Result>
        <Box padding="5">
          <Divider />
        </Box>
        <PointsList nearbyPoints={sortedLocations} pdf />
      </div>
    </div>
  ))

  return (
    <>
      <PdfContent ref={targetRef} />
      <Content>
        <CenterContainer>
          <div ref={targetRef}>
            <div style={{ fontWeight: 'bold' }}>Uw resultaat:</div>
            <Result>
              {resultsMap[result]}{' '}
              <Link
                href="https://www.provoet.nl"
                target="_blank"
                rel="noreferrer"
              >
                www.provoet.nl
              </Link>
            </Result>
            <Online>
              {showGoogleMaps && (
                <>
                  <Box padding="5">
                    <Divider />
                  </Box>
                  <GoogleMap
                    mapContainerStyle={{
                      width: '100%',
                      height: '400px',
                    }}
                    center={userLocation}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                    }}
                  >
                    {nearbyPoints.length > 0 &&
                      nearbyPoints.map((point) => (
                        <Marker
                          key={point.id}
                          position={{
                            lat: point.lat,
                            lng: point.lng,
                          }}
                          onClick={() => {
                            setSelectedPoint(point)
                            if (mapRef.current) {
                              mapRef.current.setZoom(14)
                              mapRef.current.panTo({
                                lat: point.lat,
                                lng: point.lng,
                              })
                            }
                          }}
                        />
                      ))}
                    {selectedPoint && (
                      <InfoWindow
                        position={{
                          lat: selectedPoint.lat,
                          lng: selectedPoint.lng,
                        }}
                        onCloseClick={() => {
                          setSelectedPoint(null)
                        }}
                      >
                        <>
                          <div>{selectedPoint.company_name}</div>
                          <br />
                          <div>
                            {selectedPoint?.formatted_address.replace(
                              ', Nederland',
                              ''
                            )}
                          </div>
                          <NavigateButton
                            size="sm"
                            onClick={() =>
                              window.open(
                                `https://maps.google.com/?q=${selectedPoint.lat},${selectedPoint.lng}`,
                                '_blank'
                              )
                            }
                          >
                            Navigeren
                          </NavigateButton>
                        </>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </>
              )}
            </Online>
            <Box padding="5">
              <Divider />
            </Box>
            <PointsList nearbyPoints={sortedLocations} />
          </div>
          <div>U kunt hieronder uw advies direct downloaden</div>
          <ActionButton onClick={() => generatePDF()}>
            Download het resultaat
          </ActionButton>
        </CenterContainer>
      </Content>
    </>
  )
}

export default Summary
