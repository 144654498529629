import Layout from '../components/ui/layout/layout.jsx'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import questions from '../data/questions.js'
import Question from '../pages/Question/index.js'
import Home from '../pages/Home/Home.jsx'
import Summary from '../pages/Summary/index.js'
import PersonalData from '../pages/PersonalData/index.js'
import { AnimatePresence } from 'framer-motion'

import Faq from '../pages/Faq/Faq.jsx'
import ScrollTop from './components/ScrollTop'

const AppRouter = () => (
  <AnimatePresence>
    <Router>
      <ScrollTop />
      <Layout>
        <Routes>
          {questions.map((question) => (
            <Route
              key={question.id}
              path={`/question/${question.id}`}
              element={<Question key={question.id} question={question} />}
            />
          ))}
          <Route path="/resultaat" element={<Summary />} />
          <Route path="/personal" element={<PersonalData />} />
          <Route path="/adviezen" element={<Faq />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Layout>
    </Router>
  </AnimatePresence>
)

export default AppRouter
