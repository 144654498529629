import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  Back,
  BottomRadius,
  Header,
  HeaderContent,
  Logo,
  LogoContainer,
  SpaceContainer,
} from './styles.js'
import { FaArrowLeftLong } from 'react-icons/fa6'
import logo from '../../../assets/logo.svg'

const Layout = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const showBackArrow =
    location.pathname !== '/' && !location.pathname.startsWith('/summary/')

  return (
    <>
      <Header>
        <HeaderContent>
          {showBackArrow && (
            <Back>
              <FaArrowLeftLong color="#2c2d32" onClick={() => navigate(-1)} />
            </Back>
          )}
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
        </HeaderContent>
        <BottomRadius />
      </Header>
      <SpaceContainer>{children}</SpaceContainer>
    </>
  )
}

export default Layout
