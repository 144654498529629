import { createSlice } from '@reduxjs/toolkit'

const omit = (obj, props) => {
  obj = { ...obj }
  props.forEach((prop) => delete obj[prop])
  return obj
}

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    locations: [],
    answers: {},
    user: {},
  },
  reducers: {
    setPersonalData: (state, action) => {
      state.user = {
        ...omit(action.payload, ['checkbox1', 'checkbox2']),
        ...(action.payload.city && {
          city: {
            lat: action.payload.city.lat(),
            lng: action.payload.city.lng(),
          },
        }),
      }
    },
    updatePersonalData: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
    setAnswer: (state, action) => {
      const { questionId, subQuestionId, answer } = action.payload
      if (subQuestionId) {
        if (!state.answers[questionId]) {
          state.answers[questionId] = {}
        }
        state.answers[questionId][subQuestionId] = answer
      } else {
        state.answers[questionId] = answer
      }
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
  },
})

export const { setAnswer, setPersonalData, setLocations, updatePersonalData } =
  surveySlice.actions
export default surveySlice.reducer
