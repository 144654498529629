const questions = [
  {
    id: 1,
    text: 'Is de huid ruw en/of schilferig?',
    answers: ['Ja', 'Nee'],
    image: '/questions/f3c8bb7e0e.webp',
    subQuestions: [],
    explanations: {
      header: 'Is de huid ruw en/of schilverig?',
      text: [
        'Een droge huid ontstaat door een vermindering van de talgklierfunctie. Bij (extreem) droge voeten is de huid ruw en kan deze mogelijk schilferig en gevoelig zijn. De droge huid kan jeuken, trekken, is gespannen en extra kwetsbaar op plekken waar grote kans is op vouwen en kloven.',
        'Iedereen kan droge voeten krijgen. Wel hebben senioren en mensen met een medische aandoening, zoals diabetes mellitus, reumatische aandoeningen of huidaandoeningen een grotere kans om een droge huid te krijgen. Er kunnen meerdere oorzaken zijn waardoor u een droge huid krijgt. Zo kan bij personen met een achterliggende aandoening een droge huid een aanwijzing zijn voor de aanwezigheid van een verminderde doorbloeding of verlies van het gevoel in uw voeten. Een droge huid kan echter ook erfelijk bepaald zijn. Daarnaast kunnen weersomstandigheden van invloed zijn op uw huid.',
        'Behandeling',
        'Wanneer u droge voeten heeft zal uw pedicure of medisch pedicure de droge schilfertjes, die vaak harde randjes hebben, verwijderen. Bij een pedicure en medisch pedicure die ook cosmetische behandelingen geeft kan een ontspannende scrub- en/of peeling behandeling ervoor zorgen dat uw huid weer zacht aanvoelt. De dode huidcellen worden verwijderd, waardoor de aanmaak van nieuwe huidcellen wordt gestimuleerd en verzorgende producten beter worden opgenomen. Naast dat dit ontspannend is, is dit ook goed voor de doorbloeding van uw voeten. Daarnaast kan bij een cosmetische behandeling een masker en/of pakking uw voeten een extra hydraterende boost geven. Dit voedt uw huid intensief en zorgt voor een grondig herstel. Uw voeten worden bij de behandeling ingepakt zodat het masker extra goed intrekt. De actieve ingrediënten worden zo doeltreffend opgenomen en de afvalstromen worden afgevoerd. Een masker werkt snel en geeft de voedende werking die uw droge voeten nodig hebben. Dergelijke behandelingen worden niet door iedere pedicure en medisch pedicure gegeven en als u risicovoeten heeft mag de behandeling mogelijk niet worden uitgevoerd. De pedicure of medisch pedicure zal het eelt van uw voeten verwijderen en uw voeten na de behandeling goed insmeren met een hydraterende crème die geschikt is voor uw huidtype.',
      ],
      images: ['/explanations/4.jpg', '/explanations/4.1.jpg'],
    },
  },
  {
    id: 2,
    text: 'Is er een nagel afgebroken?',
    answers: ['Ja', 'Nee'],
    image: '/questions/915d2cf7de.webp',
    subQuestions: [],
    explanations: {
      header: 'Is er een nagel afgebroken?',
      text: [
        'Bij een afgebroken nagel mist er een gedeelte of zelfs een groot deel van de nagel. Een teennagel kan afbreken door bijvoorbeeld het (langdurig) onder druk staan of stoten van de nagel of na een opgelopen trauma, waarbij er bijvoorbeeld een object op de teen is gevallen. Dit kan veel pijn veroorzaken en overlast geven bij het bewegen. Soms kan het nagelbed zijn aangetast waardoor er een bloeding ontstaat. Indien er sprake is van een bloeding onder de nagelplaat dan kan de nagel als gevolg hiervan loslaten of afbreken.',
        'Behandeling',
        'Een gebroken nagel kan goed worden behandeld door uw pedicure of medisch pedicure. Indien mogelijk kan de pedicure of medisch pedicure uw nagel repareren. Bij een nagelreparatie wordt een nagelprothese gezet. Dit is een techniek waarbij de natuurlijke nagel gedeeltelijk of volledig door een kunstnagel wordt vervangen. De nagel kan worden vervaardigd met gel, acryl of een combinatieproduct. De tijdelijke kunstnagel past goed bij uw natuurlijke nagels en is ook voor heren een passende oplossing. De kunstnagel zorgt ervoor dat de oorspronkelijk gevormde nagelwallen behouden blijven.',
      ],
      images: ['/explanations/5.jpg'],
    },
  },
  {
    id: 3,
    text: 'Is er een nagel blauw?',
    answers: ['Ja', 'Nee'],
    image: '/questions/915d2cf7de.webp',
    subQuestions: [],
    explanations: {
      header: 'Is er een nagel blauw?',
      text: [
        'Een blauwe teennagel ontstaat door een bloeduitstorting onder de nagel (tussen de nagel en het nagelbed). Oorzaken kunnen verschillend zijn, bijvoorbeeld een klap of stoot op de teennagel, een verkeerd geplaatste nagelbeugel of prothese, nagelloslating of een nagel-operatie. Een blauwe nagel kan echter ook ontstaan door druk in de schoenen of kousen. Bij personen die anti-stollingsmiddelen gebruiken kan een blauwe nagel zelfs ontstaan na hele lichte druk.',
        'Behandeling',
        'Indien een blauwe nagel recent is ontstaan kan de pedicure of medisch pedicure een gaatje in uw nagelplaat boren om de druk weg te nemen. Dit is een pijnloze handeling waarna u direct verlichting zult merken. Een medisch pedicure kan met verschillende technieken de nagel begeleiden tot normale groei. Zij kan bijvoorbeeld een nagelbeugel plaatsen om de groei van uw nagel te reguleren of een prothesenagel plaatsen. Een pedicure die hiervoor bijscholing heeft gevolgd kan deze technieken ook uitvoeren. Het opnieuw laten aangroeien van een teennagel is een langdurig proces. Het is belangrijk om gedurende dit proces regelmatig de nagel te laten behandelen. Dit ter voorkoming van het ontwikkelen van een stootnagel of ingroeiende nagel.',
      ],
      images: ['/explanations/6.jpg'],
    },
  },
  {
    id: 4,
    text: 'Drukt een van nagels pijnlijk in de huid van de teen?',
    image: '/questions/915d2cf7de.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Drukt een van nagels pijnlijk in de huid van de teen?',
      text: [
        'Bij een ingroeiende nagel (pseudo unguis incarnatus) drukt de zijkant van de nagel in de huid. Dit kan veel pijnklachten geven, de omliggende huid kan rood zijn of gezwollen, maar er is geen ontsteking aanwezig. Bij een ingegroeide nagel (unguis incarnatus) groeit het puntje van de nagel in het nagelbed. Een ingegroeide teennagel heeft ontstekingsverschijnselen, veroorzaakt pijn en irritatie. Er kan een klein wondje ontstaan en dit kan gaan ontsteken waarbij er pus uit de huid komt. Zowel een ingroeiende nagel als een ingegroeide nagel ontstaan meestal aan de grote teen. Echter de andere nagels kunnen ook ingroeien. De meest voorkomende oorzaken zijn de vorm van de nagel, het wegknippen van de hoeken van de nagels, peuteren aan de nagel, een vochtige huid (dit komt bijvoorbeeld veel voor bij tieners), te smalle schoenen of een afwijkende stand van de teen.',
        'Behandeling',
        'Bij een ingroeiende nagel kunt u direct een afspraak met bij de pedicure of medisch pedicure. Hij of zij kan voorkomen dat uw nagel verder ingroeit door de groeirichting te begeleiden. Ook kunt u tips krijgen om het ingroeien van uw nagels in de toekomst te voorkomen. Is er sprake van een ingegroeide nagel met ontsteking dan is een verwijzing van uw huisarts nodig. De medisch pedicure kan eventueel een nagelbeugel plaatsen om de groei van uw nagel te reguleren.',
      ],
      images: ['/explanations/7.jpg', '/explanations/7.1.jpg'],
    },
  },
  {
    id: 5,
    text: 'Is een van de nagels dikker dan de andere nagels?',
    image: '/questions/915d2cf7de.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Is een van de nagels dikker dan de andere nagels?',
      text: [
        'Een verdikte nagel (hypertrofische nagel of onychauxis) komt voor bij zowel vinger- als teennagels. Iedereen kan hier last van krijgen. Het komt wel vaker bij ouderen voor dan bij jongeren. Behalve verdikking van de nagelplaat kan deze aandoening ook broze en/of verkleurde nagels met zich meebrengen. Als de nagelplaat verdikt is zonder vervormingen, dan wordt deze nagel onychauxis genoemd. Zijn er vervormingen van de nagelplaat dan spreken we over hypertrofische nagel. Als gevolg van het verdikken kunnen nagels minder transparant worden, gaan krullen en ze kunnen afbrokkelen of afbreken.',
        'Behandeling',
        'Verdikte nagels kunnen veel klachten geven, doordat ze overmatig druk geven op uw huid en op uw tenen bij het dragen van schoenen. Ook kan het zelf knippen van uw verdikte nagel moeilijk of onmogelijk zijn. Dit komt doordat de verdikte nagelplaat te sterk is. De behandeling van uw pedicure of medisch pedicure verhelpt of verlicht uw klachten. Niet alleen zal zij uw nagels dunner frezen, zij zal ook onderzoeken wat de oorzaak is van het ontstaan van uw verdikte nagels. Daarnaast kan zij u schoenadvies geven.',
      ],
      images: ['/explanations/8.jpg'],
    },
  },
  {
    id: 6,
    text: 'Is een van de nagels te kort en stoot deze tegen de teentop?',
    image: '/questions/915d2cf7de.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Is een van de nagels te kort en stoot deze tegen de teentop?',
      text: [
        'Een stootnagel (unguis pulsus) is een te korte nagel die tegen de voorkant van de teentop ‘stoot’. Als gevolg hiervan komt de teentop omhoog te staan. In sommige situaties kan de stootnagel met een ontsteking gepaard gaan.',
        "Een stootnagel ontstaat veelal na een trauma, waarbij de nagel gedeeltelijk is afgebroken of los heeft gelaten. Een nagel beschermt het onderliggende nagelbed en zorgt ervoor dat de teentop naar beneden wordt geduwd. Als de nagel ontbreekt of gedeeltelijk is afgebroken dan zal de teentop omhoog komen en kunnen er problemen ontstaan bij de groei van de nagel. Dit komt doordat de huid waar de nagel zat nu kans krijgt om omhoog te komen. De nieuwe nagel kan dan niet normaal groeien. De nagel ‘stoot' tegen de teentop en groeit in plaats van vooruit, omhoog of kan gaan verdikken. Met als gevolg een dikke, misvormde nagel; een stootnagel. Een stootnagel kan ook ontstaan door het langdurig dragen van verkeerd schoeisel of het verkeerd knippen van de teennagels.",
        'Behandeling',
        'Uw pedicure of medisch pedicure kan uw nagel afvlakken en de eventueel ontstane eeltrand verwijderen. Daarnaast kan een nagelprothese (kunstnagel) helpen de nagel over de opstaande huid van de teentop te begeleiden. Deze drukt de teentop naar beneden waardoor de nagel normaal door kan groeien. Ook kan uw medisch pedicure door bijvoorbeeld nagelbeugeltechniek de nagelgroei begeleiden om de ontwikkeling van een stootnagel te voorkomen. Het reguleren en repareren van nagels is een expertise van de medisch pedicures. Een pedicure die hier bijscholing voor heeft gevolgd kan deze technieken ook uitvoeren. Het is belangrijk om een stootnagel tijdens de groei regelmatig te laten behandelen door een pedicure of medisch pedicure. Naast de behandeling zal zij u adviseren over schoeisel en bescherming van de nagel.',
      ],
      images: ['/explanations/9.jpg'],
    },
  },
  {
    id: 7,
    text: 'Heeft een van de nagels een donkere kleur en klauwachtige vorm?',
    image: '/questions/915d2cf7de.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Heeft een van de nagels een donkere kleur en klauwachtige vorm?',
      text: [
        'Bij een ramshoornnagel (onychogryfose) is er sprake van extreme toename (hypertrofie) van de nagel. In eerste instantie zal de nagel verdikken zoals bij een hoornnagel. In een later stadium vertoont de nagelplaat afwijkingen waarbij deze de vorm aanneemt van een ‘ruwe klauw’ met een voorwaartse en/of zijwaartse kromming. De nagelplaat is verhard en verdikt en heeft een onregelmatige structuur.',
        'Vaak is een ramshoornnagel oorspronkelijk een hoornnagel die niet tijdig behandeld is. Hierdoor is de nagel dikker en dikker geworden en heeft een afwijkende vorm gekregen. De extreme hoornvorming zorgt dat de teennagel donker gekleurd wordt en een klauwachtige vorm aanneemt met dwarse groeven of ribbels. De nagel lijkt enigszins op een ramshoorn. Doordat de de nagel erg dik en hard wordt, kan deze niet meer (zelf) geknipt worden. Na verloop van tijd zal de nagel daardoor recht naar voren dan wel opzij gaan uitgroeien.',
        'Behandeling',
        'Een ramshoornnagel is goed te behandelen door een pedicure of medisch pedicure. Bij behandeling wordt de nagel eerst machinaal dunner gefreesd. Vervolgens wordt op de juiste lengte een dwars groefje gefreesd, zodat de nagel op die plek kan worden geknipt. Daarna wordt de nagelomgeving grondig schoongemaakt. Het doel is om de nagel zoveel mogelijk in vorm terug te krijgen en overmatig druk op het nagelbed en de nagelwortel te verminderen.',
      ],
      images: ['/explanations/10.jpg'],
    },
  },
  {
    id: 8,
    text: 'Wijkt de stand van één van de drie middelste tenen af?',
    image: '/questions/018477a016.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Wijkt de stand van één van de drie middelste tenen af?',
      text: [
        'Een standafwijking van de middelste tenen (dus niet bij de kleine en grote teen) waarbij de teen gebogen staat en de top van de teen de grond raakt noemen we een hamerteen. Het gewricht tussen het eerste en het tweede kootje en tweede en derde kootje is gebogen. Hierdoor ontstaat een kromming van de teen.',
        'Behandeling',
        'De standafwijking van de hamerteen kan alleen worden gecorrigeerd door een chirurgische behandeling. Gelukkig zijn er vele conservatieve mogelijkheden die uw pijnklachten kunnen verlichten. De medisch pedicure kan een hulpstuk (orthese) maken waardoor er minder druk op uw tenen komt te staan. Dit kan pijnklachten verminderen en eelt en likdoorns voorkomen. Het laten aanmeten van een corrigerende steunzool bij een podotherapeut kan ook een optie zijn. Als uw voeten niet meer in confectieschoenen passen kunnen orthopedische schoenen een uitkomst zijn.',
      ],
      images: ['/explanations/11.jpg'],
    },
  },
  {
    id: 9,
    text: 'Zijn er scheurtjes of is er witte uitslag tussen de tenen of op de huid te zien?',
    image: '/questions/0c7919bcc.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header:
        'Zijn er scheurtjes of is er witte uitslag tussen de tenen te zien?',
      text: [
        'Veel mensen hebben last van voetschimmel. Dit wordt ook wel huidschimmel aan de voet of zwemmerseczeem genoemd. Verreweg de meeste mensen hebben last van schimmel tussen de tenen. Heeft u last van een schimmelinfectie aan uw voeten en blijft u er voor langere tijd mee rondlopen? Dan kan de schimmel tussen de tenen zich ook uitbreiden naar schimmel op de voetzool. De medische benaming van deze aandoening is dermatomycose of tinea pedis.',
        'Een schimmelinfectie op uw voetzool en/of tussen de tenen kan jeuken en pijn veroorzaken. Het wordt gekenmerkt door schilfers, een rode huid op de voetzool welke soms sterk begrensd kan zijn en een witte uitslag tussen de tenen waarbij fissuren (scheurtjes) kunnen ontstaan. Een schimmelinfectie is te behandelen.',
        'Behandeling schimmel tussen de tenen en op de voet',
        'Huidschimmel op de voet is goed te behandelen, mits u er tijdig bij bent en bereid bent om uw voeten thuis dagelijks te behandelen. Een pedicure of medisch pedicure die aangesloten is bij ProVoet kan een biopt afnemen van uw huid en dit (laten) onderzoeken op de aanwezigheid van sporen van schimmels. Op basis van dat onderzoek ontvangt u advies.',
      ],
      images: ['/explanations/12.jpg', '/explanations/12.1.jpg'],
    },
  },
  {
    id: 10,
    text: 'Doet lopen pijn aan de onderkant van de voet?',
    image: '/questions/f3c8bb7e0e.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Doet lopen pijn aan de onderkant van de voet?',
      text: [
        'Voeten werken hard en vangen met elke stap tot wel 3 keer het lichaamsgewicht op. Het vetpolster (capiton), een ‘honinggraatvormige’ vetkamerstructuur, dat dicht onder de huid van uw voetzool zit, absorbeert deze schokken en verdeelt de krachten die hierbij komen kijken. Deze massa van dicht op elkaar zittende vetkamers beschermen de botten, gewrichten, banden, zenuwen en bloedvaten in de voet. Soms wordt de druk op het vetkamersysteem te groot waardoor kamertjes lek raken. Maar ook kan de kwaliteit van vetkamertjes door andere factoren verslechteren. Bijvoorbeeld door ziekte, medicatie of ouderdom.',
        'Bij het verdwijnen en/of verschuiven van het vetpolster zal de natuurlijke ‘schokdemper’ van de voeten minder goed of niet meer werken. Hierdoor komen met name de middenvoetsbeentjes meer met de ondergrond in aanraking. Dit kan (pijn)klachten aan de voeten geven bij het staan en lopen, maar ook in ruststand kan men last ervaren.',
        'Behandeling',
        'Herstellen van de vetkamerstructuur van uw voeten is helaas niet mogelijk. De pedicure en medisch pedicure van ProVoet kan ontstane problemen zoals eelt of likdoorns behandelen. Zij kan u adviseren over de voor u geschikte schoenen en indien nodig kan zij u doorverwijzen naar een podotherapeut voor steunzolen. De medisch pedicure kan indien nodig een orthese maken om ontstane drukplekken te weren. De pedicure en medisch pedicure kan u adviseren over de mogelijkheden van hulpmiddelen zoals een confectie inlegzool voor ondersteuning. Indien nodig zal zij u doorverwijzen naar de podotherapeut om steunzolen te laten aanmeten.',
      ],
      images: ['/explanations/13.jpg'],
    },
  },
  {
    id: 11,
    text: 'Zit er een blaar op de voet?',
    image: '/questions/68d6ad7243.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Zit er een blaar op de voet?',
      text: [
        'Blaren (bulla) zijn een ophoping van vocht onder de bovenste huidlaag. De opperhuid is niet beschadigd, maar de ondergelegen huidlagen wel. Hierdoor ontstaat een blaas, welke zeer pijnlijk kan zijn.',
        'Er zijn verschillende typen blaren te onderscheiden die door verschillende oorzaken kunnen ontstaan. Voorbeelden zijn: drukblaar, brandblaar en bloedblaar. Een drukblaar, welke ook wel wrijvingsblaar of waterblaar wordt genoemd, is de meest bekende en komt het vaakst voor. Een drukblaar kenmerkt zich doordat het vocht in het blaasje helder van kleur is.',
        'Te veel wrijving kan onder andere ontstaan door slecht passend schoeisel en/of sokken of bijvoorbeeld ongetraind langdurig lopen. Blaren op de voet komen vaak voor bij sporters. Blaren kunnen ontstaan door overmatige wrijving maar ook door medicatie of onderliggende aandoeningen zoals bijvoorbeeld diabetes mellitus.',
        'Een blaar kan onprettig aanvoelen, maar is over het algemeen niet gevaarlijk. Een uitzondering hierbij is als u diabetes mellitus heeft en blaren krijgt. In die situatie dient de blaar altijd door uw medisch pedicure te worden behandeld.',
        'Behandeling',
        'Een blaar hoeft niet altijd te worden behandeld. Heeft u geen last van de blaar dan is het beste om de huid intact te laten. De blaar zal dan vanzelf indrogen.',
        'Maar wanneer u er veel last van ondervindt dan is een bezoek aan een pedicure of medisch pedicure aan te raden. Bij aanhoudende pijnklachten als gevolg van de blaar kan er voor worden gekozen om de blaar open te maken. Dit mag alleen bij blaren die zijn ontstaan door overmatige wrijving en als er geen sprake is van een onderliggende aandoening.',
      ],
      images: ['/explanations/14.jpg'],
    },
  },
  {
    id: 12,
    text: 'Is er sprake van overmatige eeltvorming?',
    image: '/questions/af37cde542.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Is er sprake van overmatige eeltvorming',
      text: [
        'Eelt is een plaatselijke verdikking van de bovenlaag van uw huid (ook wel de opperlaag). Het ontstaat van nature op plekken waar de huid last heeft van druk en wrijving. Onder de voet is vaak een dun laagje eelt aanwezig dat ter bescherming van de huid dient. Dit noemen we fysiologisch eelt. In sommige situaties blijft de hoornlaag verdikken waardoor er pijnklachten kunnen ontstaan. Dan is er sprake van overmatig ofwel pathologisch eelt. Het is dan ook van belang om te zorgen dat dit eelt regelmatig door de pedicure of medisch pedicure wordt verwijderd.',
        'Behandeling',
        'Heeft u last van overmatige eeltvorming? Dan adviseren wij een ProVoet pedicure of medisch pedicure te bezoeken. De pedicure helpt u niet alleen om het hardnekkig eelt weg te halen, maar helpt ook om eventuele likdoorns te behandelen. Daarnaast kan de pedicure de oorzaken van uw eeltvorming achterhalen en preventieve maatregelen adviseren.',
      ],
      images: ['/explanations/15.jpg', '/explanations/15.1.jpg'],
    },
  },
  {
    id: 13,
    text: 'Zitten er barsten of scheuren in de voet (Kloven)?',
    image: '/questions/68d6ad7243.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Zitten er barsten of scheuren in de voet?',
      text: [
        'Kloven in de voet (raghaden) zijn barsten of scheuren in de huid. Voetkloven (en hielkloven) kunnen in hard overmatig eelt, of juist in een droge huid zonder overmatig eelt ontstaan. Kloven kunnen behoorlijk diep zijn en zorgen voor pijnlijke voeten. Barsten in hard en droog eelt vereisen behandeling door een pedicure of medisch pedicure en een goede thuiszorg. De randen van kloven in voeten zijn vaak hard. Onderin de kloof is de huid echter erg dun. Hierdoor kan de voet snel gaan bloeden. De meest voorkomende plek waar kloven voorkomen zijn op de rand van de hiel, onder de voorvoet en soms onder de rand van de grote teen. Kloven ontstaan meestal op plaatsen waar veel druk is met de ondergrond. Kloven tussen de tenen zijn geen kloven maar fissuren. Deze ontstaan door een weke huid en kunnen gepaard gaan met een schimmelinfectie. Meer over informatie over fissuren leest u in schimmelaandoeningen aan uw huid.',
        'Behandeling',
        'De pedicure en medisch pedicure zal uw kloven behandelen door de harde randen te verwijderen. Deze randen belemmeren de genezing van de zachte kwetsbare huid in de diepte van de kloof. De pedicure en medisch pedicure zal bij bloedende kloven de wondjes behandelen en u advies meegeven voor verdere behandeling thuis.',
      ],
      images: ['/explanations/16.jpg', '/explanations/16.1.jpg'],
    },
  },
  {
    id: 14,
    text: 'Is er sprake van bloemkoolachtige uitgroeisels (wratten)?',
    image: '/questions/af906a0a8.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Is er sprake van bloemkoolachtige uitgroeisels (wratten)?',
      text: [
        'Wratten (verrucae vulgaris) zijn goedaardige bloemkoolachtige uitgroeisels van de huid. Een voetwrat is besmettelijk en kan ontstaan doordat uw voet in contact is gekomen met het humane papillomavirus. De kans op een besmetting is net als bij een voetschimmel (lees ook onze onderwerpen schimmelnagel of huidschimmel) besmetting het grootst in ruimtes waar meerdere personen met blote voeten lopen zoals bijvoorbeeld sportgelegenheden, openbare zwembaden, gemeenschappelijk douches en sauna gelegenheden. Maar besmetting kan vanzelfsprekend ook bij andere gelegenheden worden opgedaan.',
        'Behandelen',
        'Als u een voetwrat heeft of denkt te hebben, adviseren wij u contact op te nemen met uw huisarts. De huisarts kan uw wrat verwijderen door bevriezing met vloeibare stikstof (het aanstippen van uw wrat). Hierdoor wordt het virus echter niet uitgeroeid en wratten kunnen daardoor terugkeren. De behandeling is daarbij ook pijnlijk. De huisarts kan u doorverwijzen naar een pedicure of medisch pedicure die gespecialiseerd is in voetwratten. Vaak kan deze uw wrat op pijnloze wijze behandelen.',
        'Pedicures en medisch pedicures aangesloten bij brancheorganisatie ProVoet zijn opgeleid om het eelt van uw voetwratten af te halen. Daarnaast kan de medisch pedicure bij pijnklachten de voetwrat drukvrij leggen.',
      ],
      images: ['/explanations/17.jpg'],
    },
  },
  {
    id: 15,
    text: 'Is er een likdoorn/ eksteroog te vinden (naar binnen groeiende eeltplek)?',
    image: '/questions/018477a016.webp',
    answers: ['Ja', 'Nee'],
    subQuestions: [],
    explanations: {
      header: 'Is er een naar binnengroeiende eeltplek te vinden (likdoorn)?',
      text: [
        'Een likdoorn (clavus) is een vaak pijnlijke, conisch naar binnen groeiende eeltplek (verharde eeltkern). Deze komt voor op plaatsen waar veel en vaak druk en/of wrijving op de huid wordt uitgeoefend. Dit zorgt voor overmatige eeltvorming op een geconcentreerde plek van uw voet. Een likdoorn komt vaak voor op de voetzool, de bovenzijde van de tenen, op de teentop en tussen de tenen. Maar ook op handpalmen kunnen likdoorns ontstaan. Een likdoorn kan pijnlijk zijn, het kan voelen alsof er een spijker in de voet zit.',
        'Behandeling',
        'Een pedicure of medisch pedicure kan vakkundig de likdoorn verwijderen door deze handmatig en machinaal te behandelen. het wegsnijden en -frezen van de eeltpit. Deze zal dan direct verdwijnen en daarmee ook de pijn. De pedicure of medisch pedicure zal uw voeten onderzoeken om te kijken waardoor de likdoorn is ontstaan. Hij of zij zal u adviseren hoe u een likdoorn kunt voorkomen.',
      ],
      images: [
        '/explanations/18.jpg',
        '/explanations/18.1.jpg',
        '/explanations/18.2.jpg',
        '/explanations/18.3.jpg',
      ],
    },
  },
  {
    id: 16,
    text: 'Heeft u een van de volgende ziektebeelden?',
    subtitle:
      'Wij stellen u deze vraag omdat bovenstaande ziektebeeldeninvloed kunnen hebben op de conditie van uw voeten. Mocht u op één van deze vragen ja hebben geantwoord, dan verwijzen we u voor een behandeling naar een medisch pedicure.',
    answers: ['Ja', 'Nee'],
    subQuestions: [
      {
        id: 1,
        text: 'Diabetes Mellitus Type 1',
      },
      {
        id: 2,
        text: 'Diabetes Mellitus Type 2',
      },
      {
        id: 3,
        text: 'Hart- en vaatziekten',
      },
      {
        id: 4,
        text: 'Gewrichtsaandoeningen',
      },
      {
        id: 5,
        text: 'Reuma',
      },
      {
        id: 6,
        text: 'Parkinson',
      },
      {
        id: 7,
        text: 'Oncologische ziekte (kanker)',
      },
    ],
    explanations: null,
  },
  // {
  //   id: 17,
  //   text: 'Heeft u last van een of meerder van de volgende ziektebeelden hieronder?:',
  //   subtitle:
  //     'Iedere voet past een andere schoen. Ook voor schoenadvies kunt u terecht bij de pedicure.',
  //   answers: ['Ja', 'Nee'],
  //   subQuestions: [
  //     {
  //       id: 1,
  //       text: 'Aangepaste confectieschoen(en)',
  //     },
  //     {
  //       id: 2,
  //       text: 'Orthopedische schoen',
  //     },
  //     {
  //       id: 3,
  //       text: 'Semi-orthopedische schoen(en)',
  //     },
  //     {
  //       id: 4,
  //       text: 'Werkschoenen',
  //     },
  //     {
  //       id: 5,
  //       text: 'Zolen en/of supplementen',
  //     },
  //     {
  //       id: 6,
  //       text: 'Orthese',
  //     },
  //     {
  //       id: 7,
  //       text: 'Elastische-therapeutische kous(en)',
  //     },
  //   ],
  //   explanations: null,
  // },
]

export default questions
