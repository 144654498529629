import Content from '../../components/ui/content/content.jsx'

const Faq = () => (
  <Content>
    <div style={{ padding: 20 }}>
      <h2
        style={{
          textAlign: 'start',
          color: '#2c2d32',
          fontSize: '1.5em',
        }}
      >
        <b>Een aantal tips voor de verzorging van uw voeten:</b>
      </h2>
      <br/>
      <ul
        style={{
          textAlign: 'start',
          color: '#2c2d32',
          fontSize: 16,
          paddingLeft: '15px'
        }}
      >
        <li style={{ color: '#2c2d32' }}>
          Was uw voeten dagelijks, het liefst zonder zeep
        </li>
        <li style={{ color: '#2c2d32' }}>
          Droog uw voeten goed af, ook tussen de tenen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Gebruik een voetcrème of lotion om de huid soepel en in conditie te
          houden
        </li>
        <li style={{ color: '#2c2d32' }}>
          Smeer de crème/lotion niet tussen uw tenen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Knip uw teennagels recht en niet te kort. Knip geen hoekjes af, om
          ingroeien te voorkomen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Houd uw voeten soepel, door dagelijks voetoefeningen te doen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Koop goed passende (leren) schoenen met voldoende ruimte voor de tenen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Laat uw voeten altijd opmeten en draag uw eigen kousen/sokken bij het
          passen van schoenen
        </li>
        <li style={{ color: '#2c2d32' }}>
          Draag kousen/sokken zonder naden en voorkom dat de kousen/sokken
          knellen&nbsp;
        </li>
        <li style={{ color: '#2c2d32' }}>
          Kies voor sokken van natuurlijk, absorberend materiaal. Denk aan
          katoen of wol
        </li>
        <li style={{ color: '#2c2d32' }}>
          Trek elke dag een paar schone sokken aan
        </li>
        <li style={{ color: '#2c2d32' }}>Wissel regelmatig van schoenen</li>
        <li style={{ color: '#2c2d32' }}>
          Laat eelt, likdoorns, kloven en nagelproblemen behandelen door een
          pedicure of medisch pedicure aangesloten bij ProVoet
        </li>
      </ul>
    </div>
  </Content>
)

export default Faq
